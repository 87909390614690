import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RouteComponentProps } from "react-router-dom";
import { requestApplications } from "../actions";

interface Props extends RouteComponentProps<any> {
  onChange: (name: string) => {};
  requestApplications: () => Promise<void>;
  applications: any[];
  selected: string;
}

class ApplicationsList extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.requestApplications();
  }

  onChange = (e) => {
    this.props.onChange(e.target.value);
  };

  render() {
    return (
      <div className="form-group">
        <select
          value={this.props.selected || "none"}
          className="form-control"
          id="exampleFormControlSelect1"
          onChange={this.onChange}
        >
          <option value={"none"} disabled>
            No Application
          </option>
          {this.props.applications.map((a) => (
            <option key={a} value={a}>
              {a}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ requestApplications }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
  const { applications } = state;
  return { applications };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsList);
