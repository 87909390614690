import * as React from "react";
import { Link } from "react-router-dom";

const ProjectsListElement = (props: { _id: string; name: string }) => (
  <Link to={`/project/${props._id}`} className="list-group-item">
    {props.name}
  </Link>
);

export default ProjectsListElement;
