import * as React from "react";

const SampleButtonBar = (props: { onDelete: () => void }) => (
  <button
    type="button"
    className="btn btn-danger btn-xs"
    onClick={props.onDelete}
  >
    X
  </button>
);

export default SampleButtonBar;
