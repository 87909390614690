/* eslint-disable react/style-prop-object */
import * as React from "react";
import LiveSetAnalysisListGroup from "./LiveSetAnalysisListGroup";

interface Props {
  filename: string;
  errors: string[];
  warnings: string[];
  onAccept: () => void;
  onRetry: () => void;
}

export default class LiveSetAnalysis extends React.Component<Props, {}> {
  render() {
    const { filename, errors, warnings } = this.props;
    const canUpload = errors.length === 0;

    return (
      <div className="row">
        <div id="liveset-upload-container" className="col-xs-12">
          <div id="liveset-upload-dropzone">
            <div className="row">
              <div className="col-xs-12">
                <h3>{filename}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12" style={{ margin: "2em 0" }}>
                <div className="btn-group">
                  <button
                    className="btn btn-lg btn-success"
                    disabled={!canUpload}
                    onClick={this.props.onAccept}
                  >
                    <span className="glyphicon glyphicon-upload" /> Upload
                  </button>
                  <button
                    className="btn btn-lg btn-warning"
                    onClick={this.props.onRetry}
                  >
                    <span className="glyphicon glyphicon-repeat" /> Retry
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <LiveSetAnalysisListGroup
                  name="errors"
                  items={errors}
                  style="panel-danger"
                />
                <LiveSetAnalysisListGroup
                  name="warnings"
                  items={warnings}
                  style="panel-warning"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
