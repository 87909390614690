import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LiveSetDropzone from "../components/LiveSetDropzone";
import LiveSetUploadProgress from "../components/LiveSetUploadProgress";
import LiveSetAnalysis from "../components/LiveSetAnalysis";
import LiveSetUploadError from "../components/LiveSetUploadError";
import { analyseLiveSet, createLiveSet } from "../actions";
import {
  REQUEST_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
} from "../actions/types";

interface Props {
  status: string;
  analysis: any;
  error: Error;
  uploadAction: (file: File) => void;
  analyseLiveSet: (file: File) => void;
}

interface State {
  file: File | null;
}

class LiveSetUploadFlow extends React.Component<Props, State> {
  state: State = { file: null };

  handleDrop = (file: File) => {
    this.setState({ file });
    this.props.analyseLiveSet(file);
  };

  onAccept = () => {
    this.props.uploadAction(this.state.file!);
  };

  onRetry = () => {
    this.props.analyseLiveSet(this.state.file!);
  };

  render() {
    const { status } = this.props;
    const { file } = this.state;
    if (!file) {
      return <LiveSetDropzone onLiveSetDrop={this.handleDrop} />;
    } else if (status === REQUEST_ACTIVE) {
      return <LiveSetUploadProgress />;
    } else if (status === REQUEST_SUCCESS) {
      return (
        <LiveSetAnalysis
          filename={this.state.file!.name}
          errors={this.props.analysis.errors}
          warnings={this.props.analysis.warnings}
          onAccept={this.onAccept}
          onRetry={this.onRetry}
        />
      );
    } else if (status === REQUEST_FAILED) {
      return <LiveSetUploadError error={this.props.error} />;
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ analyseLiveSet, createLiveSet }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
  const { status, analysis, error } = state.uploads.liveset;
  return { ...ownProps, status, analysis, error };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveSetUploadFlow);
