export const REQUEST_ACTIVE = "REQUEST_ACTIVE";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FAILED = "REQUEST_FAILED";

export const PROJECTS_REQUEST = "projects/PROJECTS_REQUEST";
export const PROJECTS_REQUEST_FAILED = "projects/PROJECTS_REQUEST_FAILED";
export const PROJECTS_REQUEST_SUCCESS = "projects/PROJECTS_REQUEST_SUCCESS";
export const PROJECT_REQUEST = "projects/PROJECT_REQUEST";
export const PROJECT_REQUEST_FAILED = "projects/PROJECT_REQUEST_FAILED";
export const PROJECT_REQUEST_SUCCESS = "projects/PROJECT_REQUEST_SUCCESS";
export const PROJECT_REMOVE_LIVESET = "projects/PROJECT_REMOVE_LIVESET";

export const LIVESET_REQUEST = "liveset/LIVESET_REQUEST";
export const LIVESET_REQUEST_FAILED = "liveset/LIVESET_REQUEST_FAILED";
export const LIVESET_REQUEST_SUCCESS = "liveset/LIVESET_REQUEST_SUCCESS";

export const LIVESET_UPLOADING = "liveset/LIVESET_UPLOADING";
export const LIVESET_SUCCESS = "liveset/LIVESET_SUCCESS";
export const LIVESET_FAILED = "liveset/LIVESET_FAILED";

export const SAMPLE_UPLOADING = "sample/SAMPLE_UPLOADING";
export const SAMPLE_SUCCESS = "sample/SAMPLE_SUCCESS";
export const SAMPLE_FAILED = "sample/SAMPLE_FAILED";
export const SAMPLE_PROGRESS = "sample/SAMPLE_PROGRESS";

export const APPLICATIONS_REQUESTED = "APPLICATIONS_REQUESTED";
export const APPLICATIONS_RECEIVED = "APPLICATIONS_RECEIVED";
