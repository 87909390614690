import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import App from "./App";
import Project from "./containers/Project";
import ProjectsList from "./containers/ProjectsList";
import ProjectCreate from "./containers/ProjectCreate";
import LiveSetCreate from "./containers/LiveSetCreate";
import LiveSetReplace from "./containers/LiveSetReplace";
import FileManager from "./containers/FileManager";

export const renderRoutes = () => (
  <BrowserRouter>
    <App>
      <Route exact path="/" component={ProjectsList} />
      <Route path="/liveset/create/:projectID" component={LiveSetCreate} />
      <Route path="/liveset/replace/:id" component={LiveSetReplace} />
      <Route exact path="/project" component={ProjectCreate} />
      <Route path="/project/:id" component={Project} />
      <Route path="/filemanager/:id" component={FileManager} />
    </App>
  </BrowserRouter>
);
