/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

interface Props {
  value: string;
  size: "lg" | "m" | "sm" | "xs";
  onAccept: () => void;
}

const ConfirmButton = (props: Props) => {
  const [showDropDown, setShowDropDown] = React.useState(false);
  if (showDropDown === true) {
    return (
      <div className="btn-group">
        <button
          type="button"
          className={`btn btn-danger btn-${props.size} dropdown-toggle`}
          data-toggle="dropdown"
        >
          Are you sure? <span className="caret"></span>
        </button>
        <ul className="dropdown-menu">
          <li>
            <a href="#" onClick={() => setShowDropDown(false)}>
              No
            </a>
          </li>
          <li>
            <a href="#" onClick={props.onAccept}>
              Yes
            </a>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <button
        id="delete"
        className={`btn btn-danger btn-${props.size}`}
        onClick={() => setShowDropDown(true)}
      >
        {props.value}
      </button>
    );
  }
};

export default ConfirmButton;
