import { combineReducers } from "redux";
import projects from "./projects";
import liveSets from "./livesets";
import uploads from "./uploads";
import applications from "./applications";

const rootReducer = combineReducers({
  applications,
  projects,
  liveSets,
  uploads,
});

export default rootReducer;
