import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { createProject } from "../actions";

interface Props extends RouteComponentProps<any> {
  createProject: (name: string) => Promise<void>;
  match: any;
  history: any;
}

interface State {
  name: string;
}

class ProjectCreate extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { name: "" };
  }

  render() {
    const { name } = this.state;
    return (
      <div className="row">
        <div className="col-xs-4"></div>
        <div className="col-xs-4 text-center">
          <img
            src="/project.png"
            className="large-project-icon"
            alt="Project"
          />
          <form id="project-form" onSubmit={this.handleSubmit}>
            <div className="input-group">
              <input
                type="input"
                className="form-control"
                placeholder="Project Name"
                name="project-name"
                value={name}
                onChange={this.handleChange}
              />
              <span className="input-group-btn">
                <input
                  className="btn btn-default btn-success"
                  type="submit"
                  value="Create"
                  id="submitButton"
                />
              </span>
            </div>
          </form>
        </div>
        <div className="col-xs-4"></div>
      </div>
    );
  }

  handleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { name } = this.state;
    createProject(name).then((data: any) => {
      this.props.history.push(`/project/${data._id}`);
    });
  };
}

export default connect(null)(ProjectCreate);
