import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loader from "../components/Loader";
import { fetchProjects } from "../actions";
import { REQUEST_SUCCESS } from "../actions/types";
import ProjectsListElement from "../components/ProjectsListElement";

interface Props {
  projects: any[];
  request: any;
  fetchProjects: () => (dispatch: any) => void;
}

class ProjectsOverview extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.fetchProjects();
  }

  render() {
    const { projects, request } = this.props;
    if (request !== REQUEST_SUCCESS) return <Loader />;

    return (
      <div>
        <div className="page-header text-center">
          <h1>Projects</h1>
        </div>
        <ul className="list-group">
          {projects.map((project) => {
            console.log(project);
            return (
              <ProjectsListElement
                key={project._id}
                _id={project._id}
                name={project.name}
              />
            );
          })}
          <Link
            to="/project"
            className="list-group-item list-group-item-success"
          >
            <span className="glyphicon glyphicon-plus"></span> New Project
          </Link>
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchProjects }, dispatch);
};

const mapStateToProps = (state) => {
  const { projects, request } = state.projects.projectsList;
  return { projects, request };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsOverview);
