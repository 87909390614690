import * as $ from "jquery";

import {
  PROJECTS_REQUEST,
  PROJECTS_REQUEST_FAILED,
  PROJECTS_REQUEST_SUCCESS,
  PROJECT_REQUEST,
  PROJECT_REQUEST_FAILED,
  PROJECT_REQUEST_SUCCESS,
} from "./types";

export function fetchProject(id) {
  return (dispatch) => {
    dispatch({ type: PROJECT_REQUEST });
    fetch(`https://spo-composer-backend.herokuapp.com/api/projects/${id}`)
      .then((response) => response.json())
      .then((project) => {
        console.log({ project });
        dispatch({ type: PROJECT_REQUEST_SUCCESS, project });
      })
      .catch((e) => {
        console.error(`Fetching Project with iID ${id} failed...`, e);
        dispatch({ type: PROJECT_REQUEST_FAILED });
      });
  };
}

export function createProject(name: string) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: "https://spo-composer-backend.herokuapp.com/api/projects",
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify({ name }),
      success: resolve,
      error: reject,
    });
  });
}

export function deleteProject(id: string) {
  return fetch(
    `https://spo-composer-backend.herokuapp.com/api/projects/${id}`,
    {
      method: "DELETE",
    }
  );
}

export function fetchProjects() {
  return (dispatch) => {
    dispatch({ type: PROJECTS_REQUEST });

    return fetch("https://spo-composer-backend.herokuapp.com/api/projects")
      .then((response) => response.json())
      .then((response) => {
        const { projects } = response as any;
        dispatch({ type: PROJECTS_REQUEST_SUCCESS, projects });
      })
      .catch(() => dispatch({ type: PROJECTS_REQUEST_FAILED }));
  };
}

export function setProjectApplicationName(id: string, applicationName: string) {
  return (dispatch) => {
    $.ajax({
      url: `/api/projects/${id}`,
      type: "PATCH",
      contentType: "application/json",
      data: JSON.stringify({ applicationName }),
      success: () => {
        return dispatch(fetchProject(id));
      },
      error: (e) => {
        console.error(e);
      },
    });
  };
}
