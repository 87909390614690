import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeSample } from "../actions/sample";
import {
  REQUEST_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
} from "../actions/types";

import SampleButtonBar from "../components/SampleButtonBar";
import SampleUploadBar from "../components/SampleUploadBar";

interface Props {
  _id: string;
  status: any;
  progress: number;
  uploadDate: Date;
  removeSample: (id: string) => void;
}

class SampleStatus extends React.Component<Props, {}> {
  onDelete = () => {
    const { _id } = this.props;
    this.props.removeSample(_id);
  };

  render() {
    const { status, progress, uploadDate } = this.props;

    if (uploadDate) {
      return <SampleButtonBar onDelete={this.onDelete} />;
    } else {
      if (status === REQUEST_ACTIVE) {
        return <SampleUploadBar progress={progress} />;
      } else if (status === REQUEST_SUCCESS) {
        return <span>HURRAY!</span>;
      } else if (status === REQUEST_FAILED) {
        return <span>Error</span>;
      } else {
        return <span>MISSING</span>;
      }
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeSample }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
  const { _id } = ownProps;
  const { samples } = state.uploads;
  const status = samples.requests[_id];
  const progress = samples.progress[_id];
  return { ...ownProps, status, progress };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleStatus);
