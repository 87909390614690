import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import { createLiveSet } from "../actions";
import LiveSetUploadFlow from "../containers/LiveSetUploadFlow";

interface Props extends RouteComponentProps<any> {
  createLiveSet: (file: File, projectID: string) => Promise<void>;
  match: any;
  history: any;
}

class LiveSetCreate extends React.Component<Props, {}> {
  uploadAction = (file: File) => {
    const { createLiveSet, match } = this.props;
    const { projectID } = match.params;
    createLiveSet(file, projectID)
      .then(() => {
        this.props.history.push(`/project/${projectID}`);
      })
      .catch((e) => console.error(e));
  };

  render() {
    return <LiveSetUploadFlow uploadAction={this.uploadAction} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createLiveSet }, dispatch);
};

export default connect(null, mapDispatchToProps)(LiveSetCreate);
