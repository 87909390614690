import {
  LIVESET_UPLOADING,
  LIVESET_SUCCESS,
  LIVESET_FAILED,
  REQUEST_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
} from "../../actions/types";

const initialState = {
  status: null,
  analysis: null,
  error: null,
};

export default function liveset(state = initialState, action) {
  switch (action.type) {
    case LIVESET_UPLOADING:
      return { status: REQUEST_ACTIVE };

    case LIVESET_SUCCESS:
      return { ...state, status: REQUEST_SUCCESS, analysis: action.analysis };

    case LIVESET_FAILED:
      return { ...state, status: REQUEST_FAILED, error: action.error };

    default:
      return state;
  }
}
