import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { replaceLiveSet } from "../actions";
import LiveSetUploadFlow from "../containers/LiveSetUploadFlow";

interface Props extends RouteComponentProps<any> {
  replaceLiveSet: (id: string, file: File) => Promise<void>;
  match: any;
  history: any;
}

class LiveSetCreate extends React.Component<Props, {}> {
  uploadAction = (file: File) => {
    const { replaceLiveSet, match } = this.props;
    const { id } = match.params;
    replaceLiveSet(id, file)
      .then(() => {
        this.props.history.push(`/`);
      })
      .catch((e) => console.error(e));
  };

  render() {
    return <LiveSetUploadFlow uploadAction={this.uploadAction} />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ replaceLiveSet }, dispatch);
};

export default connect(null, mapDispatchToProps)(LiveSetCreate);
