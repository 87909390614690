import * as React from "react";

const LiveSetUploadError = (props: { error: Error }) => (
  <div id="liveset-upload-dropzone">
    <h1>Failed!</h1>
    <h3>{props.error.message}</h3>
  </div>
);

export default LiveSetUploadError;
