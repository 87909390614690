import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmButton from "../components/ConfirmButton";
import { deleteLiveSet } from "../actions";

interface Props {
  _id: string;
  name: string;
  deleteLiveSet: (id: string) => Promise<void>;
}

class LiveSetsListElement extends React.Component<Props, {}> {
  remove = () => {
    const { _id } = this.props;
    this.props.deleteLiveSet(_id);
  };

  render() {
    const { _id, name } = this.props;
    return (
      <li className="list-group-item">
        {name}
        <div className="btn-group btn-group-xs pull-right" role="group">
          <Link to={`/liveset/replace/${_id}`} className="btn btn-success">
            Replace
          </Link>
          <ConfirmButton value="Remove" size="xs" onAccept={this.remove} />
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ deleteLiveSet }, dispatch);
};

export default connect(null, mapDispatchToProps)(LiveSetsListElement);
