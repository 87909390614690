import * as React from "react";
import Dropzone from "react-dropzone";

interface Props {
  onLiveSetDrop: (file: File) => void;
}

const LiveSetDropzone = (props: Props) => {
  const returnFirstLiveSet = (acceptedFiles, rejectedFiles) => {
    const liveSet = acceptedFiles.find((file) => {
      const extension = file.name.split(".").pop();
      return extension === "als";
    });

    if (liveSet) {
      props.onLiveSetDrop(liveSet);
    }
  };

  return (
    <Dropzone
      id="liveset-upload-dropzone"
      style={{}}
      multiple={false}
      disablePreview={true}
      onDrop={returnFirstLiveSet}
    >
      <img src="/set.png" className="large-project-icon" alt="Project" />
      <h3>Drop your Live Set</h3>
    </Dropzone>
  );
};

export default LiveSetDropzone;
