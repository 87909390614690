import * as firebase from "firebase/app";
import "firebase/database";

firebase.initializeApp({
  apiKey: "AIzaSyC3y2cPL333KvSbcBuV9faTyClmjDjm2gg",
  authDomain: "smartphone-orchestra.firebaseapp.com",
  databaseURL: "https://smartphone-orchestra.firebaseio.com",
  projectId: "smartphone-orchestra",
  storageBucket: "smartphone-orchestra.appspot.com",
  messagingSenderId: "78165283269",
  appId: "1:78165283269:web:99369be32e25c750e73bac",
});
