import {
  SAMPLE_UPLOADING,
  SAMPLE_SUCCESS,
  SAMPLE_FAILED,
  SAMPLE_PROGRESS,
  REQUEST_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
} from "../../actions/types";

const initialState = {
  requests: {},
  progress: {},
};

export default function liveset(state = initialState, action) {
  switch (action.type) {
    case SAMPLE_UPLOADING: {
      const requests = { ...state.requests, [action.id]: REQUEST_ACTIVE };
      return { ...state, requests };
    }

    case SAMPLE_PROGRESS: {
      const progress = { ...state.progress, [action.id]: action.progress };
      return { ...state, progress };
    }

    case SAMPLE_SUCCESS: {
      const requests = { ...state.requests, [action.id]: REQUEST_SUCCESS };
      return { ...state, requests };
    }

    case SAMPLE_FAILED: {
      const requests = { ...state.requests, [action.id]: REQUEST_FAILED };
      return { ...state, requests };
    }

    default:
      return state;
  }
}
