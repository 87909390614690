import {
  REQUEST_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  LIVESET_REQUEST,
  LIVESET_REQUEST_SUCCESS,
  LIVESET_REQUEST_FAILED,
} from "../../actions/types";

const initialState = {
  liveSet: null,
  request: REQUEST_ACTIVE,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIVESET_REQUEST:
      return { liveSet: null, request: REQUEST_ACTIVE };

    case LIVESET_REQUEST_SUCCESS:
      return { liveSet: action.liveSet, request: REQUEST_SUCCESS };

    case LIVESET_REQUEST_FAILED:
      return { liveSet: null, request: REQUEST_FAILED };

    default:
      return state;
  }
}
