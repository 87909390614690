import {
  PROJECTS_REQUEST,
  PROJECTS_REQUEST_SUCCESS,
  PROJECT_REQUEST_FAILED,
  REQUEST_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
} from "../../actions/types";

const initialState = {
  projects: [],
  request: REQUEST_ACTIVE,
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_REQUEST:
      return { projects: [], request: REQUEST_ACTIVE };

    case PROJECTS_REQUEST_SUCCESS:
      return { projects: action.projects, request: REQUEST_SUCCESS };

    case PROJECT_REQUEST_FAILED:
      return { projects: [], request: REQUEST_FAILED };

    default:
      return state;
  }
}
