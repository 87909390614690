import {
  LIVESET_REQUEST,
  LIVESET_REQUEST_FAILED,
  LIVESET_REQUEST_SUCCESS,
  LIVESET_SUCCESS,
  LIVESET_FAILED,
  LIVESET_UPLOADING,
  PROJECT_REMOVE_LIVESET,
} from "./types";

export function fetchLiveSet(id) {
  return (dispatch) => {
    dispatch({ type: LIVESET_REQUEST });
    fetch(`https://spo-composer-backend.herokuapp.com/api/livesets/${id}`)
      .then((response) => response.json())
      .then((liveSet) => {
        dispatch({ type: LIVESET_REQUEST_SUCCESS, liveSet });
      })
      .catch((e) => {
        console.error("requesting liveset failed", e);
        dispatch({ type: LIVESET_REQUEST_FAILED });
      });
  };
}

export function createLiveSet(file: File, projectID: string) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("project", projectID);
    formData.append("file", file);
    return dispatch(
      uploadLiveSetFile(
        "https://spo-composer-backend.herokuapp.com/api/livesets",
        formData,
        "POST"
      )
    );
  };
}

export function deleteLiveSet(id: string) {
  return (dispatch) => {
    return fetch(
      `https://spo-composer-backend.herokuapp.com/api/livesets/${id}`,
      {
        method: "DELETE",
      }
    ).then(() => {
      dispatch({ type: PROJECT_REMOVE_LIVESET, id });
    });
  };
}

export function analyseLiveSet(file: File) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadLiveSetFile(
        "https://spo-composer-backend.herokuapp.com/api/analyse",
        formData,
        "POST"
      )
    );
  };
}

export function replaceLiveSet(id: string, file: File) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    return dispatch(uploadLiveSetFile(`/api/livesets/${id}`, formData, "PUT"));
  };
}

export function uploadLiveSetFile(url: string, body: FormData, method: string) {
  return (dispatch) => {
    dispatch({ type: LIVESET_UPLOADING });
    return fetch(url, { method, body }).then((response) => {
      return response.json().then((analysis) => {
        if (response.status !== 200) {
          const error = new Error(analysis.error);
          dispatch({ type: LIVESET_FAILED, error });
          throw error;
        } else {
          dispatch({ type: LIVESET_SUCCESS, analysis });
        }
      });
    });
  };
}
