import * as Util from "../utils";
import { uploadSample } from "./sample";
import { APPLICATIONS_REQUESTED, APPLICATIONS_RECEIVED } from "./types";

export function audioFilesDropped(
  files: File[],
  projectFiles: any[],
  dragOver: string
) {
  return (dispatch) => {
    if (dragOver && files.length === 1) {
      const sample = projectFiles.find((file) => file._id === dragOver);
      const eligbleFiles = Util.filesWithSameExtensionAsSample(files, sample);
      if (eligbleFiles.length) {
        dispatch(uploadSample(dragOver, eligbleFiles[0]));
      }
    } else {
      projectFiles
        .filter((i) => i.uploadDate == null)
        .map((sample) => {
          return {
            sample,
            files: Util.filesWithSameFilenameAsSample(files, sample),
          };
        })
        .filter((i) => i.files.length)
        .forEach((upload) => {
          dispatch(uploadSample(upload.sample._id, upload.files[0]));
        });
    }
  };
}

export function requestApplications() {
  return (dispatch) => {
    dispatch({ type: APPLICATIONS_REQUESTED });
    fetch("https://spo-composer-backend.herokuapp.com/api/applications")
      .then((response) => response.json())
      .then((applications) => {
        dispatch({ type: APPLICATIONS_RECEIVED, applications });
      });
  };
}

export * from "./project";
export * from "./liveset";
export * from "./sample";
