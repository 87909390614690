import * as React from "react";
import { connect } from "react-redux";
import SampleFilename from "../components/SampleFilename";
import SampleStatus from "./SampleStatus";

interface Props {
  _id: string;
  filename: string;
  project: string;
  uploadDate: Date;
  onDragEnter: (id) => void;
  onDragLeave: (id) => void;
}

class FileManagerSample extends React.Component<Props, {}> {
  onDragEnter = () => {
    this.props.onDragEnter(this.props._id);
  };

  onDragLeave = () => {
    this.props.onDragLeave(this.props._id);
  };

  render() {
    const { _id, filename, uploadDate } = this.props;

    return (
      <tr
        key={_id}
        className={uploadDate ? "" : "warning"}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
      >
        <td>
          <SampleFilename filename={filename} />
        </td>
        <td>
          <SampleStatus _id={_id} uploadDate={uploadDate} />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { ...ownProps };
};

export default connect(mapStateToProps)(FileManagerSample);
