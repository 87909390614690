import {
  APPLICATIONS_REQUESTED,
  APPLICATIONS_RECEIVED,
} from "../actions/types";

const initialState = [];

export default function applications(state = initialState, action) {
  switch (action.type) {
    case APPLICATIONS_REQUESTED:
      return [];

    case APPLICATIONS_RECEIVED:
      return action.applications;

    default:
      return state;
  }
}
