import {
  REQUEST_ACTIVE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  PROJECT_REQUEST,
  PROJECT_REQUEST_SUCCESS,
  PROJECT_REQUEST_FAILED,
  PROJECT_REMOVE_LIVESET,
} from "../../actions/types";

const initialState = {
  request: REQUEST_ACTIVE,
  project: {} as any,
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case PROJECT_REQUEST:
      return { request: REQUEST_ACTIVE, project: {} };

    case PROJECT_REQUEST_SUCCESS:
      return { request: REQUEST_SUCCESS, project: action.project };

    case PROJECT_REQUEST_FAILED:
      return { request: REQUEST_FAILED, project: {} };

    case PROJECT_REMOVE_LIVESET: {
      const liveSets = state.project.liveSets.filter(
        (liveSet) => liveSet._id !== action.id
      );
      const project = { ...state.project, liveSets };
      return { ...state, project };
    }

    default:
      return state;
  }
}
