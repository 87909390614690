import * as $ from "jquery";
// import * as fetch from 'isomorphic-fetch'

import {
  SAMPLE_UPLOADING,
  SAMPLE_SUCCESS,
  SAMPLE_FAILED,
  SAMPLE_PROGRESS,
} from "./types";

export function removeSample(id: string) {
  return (dispatch) => {
    return fetch(
      `https://spo-composer-backend.herokuapp.com/api/samples/${id}`,
      {
        method: "DELETE",
      }
    );
  };
}

export function uploadSample(id: string, file: File) {
  return (dispatch) => {
    dispatch({ type: SAMPLE_UPLOADING, id });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileId", id);

    $.ajax({
      url: `https://spo-composer-backend.herokuapp.com/api/samples/${id}`,
      type: "PATCH",
      data: formData,
      async: true,
      cache: false,
      contentType: false,
      processData: false,
      xhr: () => {
        var myXhr = new window.XMLHttpRequest();
        if (myXhr.upload) {
          myXhr.upload.addEventListener(
            "progress",
            (e) => {
              if (e.lengthComputable) {
                const progress = (e.loaded / e.total) * 100;
                dispatch({ type: SAMPLE_PROGRESS, id, progress });
              }
            },
            false
          );
        }
        return myXhr;
      },
      success: (data, xhr) => {
        dispatch({ type: SAMPLE_SUCCESS, id });
      },
      error: (e) => {
        console.error(e.responseText);
        dispatch({ type: SAMPLE_FAILED, id });
      },
    });
  };
}
