import * as React from "react";

interface Props {
  name: string;
  items: string[];
  style: string;
}

export default class LiveSetAnalysisListGroup extends React.Component<
  Props,
  {}
> {
  renderListItems() {
    return this.props.items.map((item, index) => (
      <li className="list-group-item" key={index}>
        {item}
      </li>
    ));
  }

  render() {
    const { name, items } = this.props;
    const style = items.length ? this.props.style : "panel-success";

    return (
      <div className={`panel ${style}`}>
        <div className="panel-heading text-center">
          The Live Set contains <b>{items.length}</b> {name}
        </div>
        <ul className="list-group parse-feedback-list-group">
          {items && this.renderListItems()}
        </ul>
      </div>
    );
  }
}
