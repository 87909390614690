import * as React from "react";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

interface Props {
  children: any;
}

export default class App extends React.Component<Props, {}> {
  render() {
    return (
      <div id="container">
        <nav>
          <ul>
            <li>Smartphone Orchestra</li>
            <li>
              <Link to="/">Projects</Link>
            </li>
            <button onClick={() => signOut(getAuth())}>Logout</button>
          </ul>
        </nav>
        <div id="content" className="container">
          {this.props.children}
        </div>
      </div>
    );
  }
}
