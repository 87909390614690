import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RouteComponentProps } from "react-router-dom";

import { REQUEST_ACTIVE } from "../actions/types";
import { fetchProject, audioFilesDropped } from "../actions";

import Dropzone from "react-dropzone";
import FileManagerSample from "./FileManagerSample";

import * as Util from "../utils";

interface Props extends RouteComponentProps<any> {
  project: any;
  request: any;
  match: any;
  fetchProject: (id: string) => (dispatch: any) => void;
  audioFilesDropped: (
    files: File[],
    projectFiles: any[],
    dragOver: string
  ) => (dispatch: any) => void;
}

interface State {
  dragOver: string;
}

class FileManager extends React.Component<Props, State> {
  componentDidMount() {
    const { match, fetchProject } = this.props;
    const { id } = match.params;
    fetchProject(id);
  }

  onDrop = (acceptedFiles) => {
    const { audioFilesDropped, project } = this.props;
    audioFilesDropped(acceptedFiles, project.files, this.state.dragOver);
  };

  onDragEnter = (id) => {
    this.setState({ dragOver: id });
  };

  onDragLeave = (id) => {
    // if (this.state.dragOver === id) this.setState({ dragOver: null })
  };

  render() {
    const { project, request } = this.props;
    if (request === REQUEST_ACTIVE) return null;

    return (
      <Dropzone
        style={{}}
        onDrop={this.onDrop}
        disableClick={true}
        disablePreview={true}
      >
        <div className="row">
          <div className="col-xs-12">
            <table className="table table-hover table-bordered table-condensed">
              <thead>
                <tr>
                  <th>Samples found in the Project:</th>
                  <th>Uploaded Samples:</th>
                </tr>
              </thead>
              <tbody>
                {project.files.sort(Util.sortByFilename).map((file) => (
                  <FileManagerSample
                    key={file._id}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    {...file}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Dropzone>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchProject, audioFilesDropped }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
  const { project, request } = state.projects.project;
  return { project, request };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileManager);
