import * as React from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import { render } from "react-dom";
import { renderRoutes } from "./routes";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";
import thunk from "redux-thunk";

import "./firebase";

import * as $ from "jquery";

import "bootstrap/dist/css/bootstrap.css";
import "./css/main.css";

(window as any).$ = (window as any).jQuery = $;
require("bootstrap");

const LoginForm = () => {
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<any>(null);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    signInWithEmailAndPassword(getAuth(), username, password).catch(setError);
  };

  return (
    <div>
      {error && (
        <div className="alert alert-danger" role="alert">
          Login failed
          <p>{error.message}</p>
        </div>
      )}
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label>Email address</label>
          <input
            className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <input
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

const LoginGate = () => {
  const [user, setUser] = React.useState<User | null>(null);

  React.useEffect(() => {
    const unregister = onAuthStateChanged(getAuth(), setUser);
    return () => unregister();
  }, [setUser]);

  if (!user) {
    return <LoginForm />;
  }
  return renderRoutes();
};

export default LoginGate;

const store = createStore(reducer, applyMiddleware(thunk));

render(
  <Provider store={store}>
    <LoginGate />
  </Provider>,
  document.getElementById("render-target")
);
