export function sortByFilename(a, b) {
  if (a.filename < b.filename) return -1;
  if (a.filename > b.filename) return 1;
  return 0;
}

export function filesWithSameExtensionAsSample(files: File[], sample) {
  return files.filter((file) => {
    const fileExtension = file.name.split(".").pop();
    return fileExtension === sample.filename.split(".").pop();
  });
}

export function filesWithSameFilenameAsSample(files: File[], sample) {
  return files.filter((file) => {
    const fileName = file.name.split("/").pop();
    const sampleName = sample.filename.split("/").pop();
    return fileName === sampleName;
  });
}
