import * as React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchProject,
  deleteProject,
  setProjectApplicationName,
} from "../actions";
import Loader from "../components/Loader";
import LiveSetsListElement from "./LiveSetsListElement";
import ApplicationsList from "./ApplicationsList";
import { REQUEST_SUCCESS } from "../actions/types";

import ConfirmButton from "../components/ConfirmButton";

interface Props extends RouteComponentProps<any> {
  project: any;
  request: any;
  match: any;
  history: any;
  fetchProject: (id: string) => (dispatch: any) => void;
  setProjectApplicationName: (id: string, applicationName: string) => void;
  deleteProject: (id: string) => Promise<void>;
}

class Project extends React.Component<Props, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchProject(id);
  }

  remove = () => {
    const { id } = this.props.match.params;
    deleteProject(id).then(() => {
      this.props.history.push(`/`);
    });
  };

  getIncompleteFiles(files: any[] = []) {
    return files.filter((file) => !file.uploadDate);
  }

  onApplicationChange(applicationName: string) {
    const { id } = this.props.match.params;
    this.props.setProjectApplicationName(id, applicationName);
  }

  render() {
    const { project, request } = this.props;
    if (request !== REQUEST_SUCCESS) return <Loader />;

    return (
      <div>
        {this.getIncompleteFiles(project.files).length > 0 && (
          <div
            className="media-files-missing-warning"
            onClick={() =>
              this.props.history.push.call(this, `/filemanager/${project._id}`)
            }
          >
            Media files are missing. Please click here to learn more.
          </div>
        )}

        <div className="row">
          <div className="col-xs-1">
            <img
              src="/project.png"
              className="project-icon-project-overview"
              alt="Project"
            />
          </div>
          <div className="col-xs-5">
            <h1>{project.name}</h1>
          </div>
          <div className="col-xs-6">
            <ConfirmButton
              value="Delete project?"
              size="m"
              onAccept={this.remove}
            />
            <div
              className="btn btn-default"
              onClick={() =>
                this.props.history.push(`/filemanager/${project._id}`)
              }
            >
              File Manager
            </div>
            <ApplicationsList
              selected={project.applicationName}
              onChange={this.onApplicationChange.bind(this)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6">
            <div className="panel panel-info">
              <div className="panel-heading">
                This project contains the following Live Sets:
              </div>
              <div className="list-group">
                {project.liveSets.map((liveSet) => (
                  <LiveSetsListElement
                    key={liveSet._id}
                    _id={liveSet._id}
                    name={liveSet.name}
                  />
                ))}
                <Link
                  to={`/liveset/create/${project._id}`}
                  className="list-group-item list-group-item-success"
                >
                  <span className="glyphicon glyphicon-plus"></span> Add a Live
                  Set
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchProject, setProjectApplicationName },
    dispatch
  );
};

const mapStateToProps = (state, ownProps) => {
  return state.projects.project;
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
