import * as React from "react";

const SampleUploadBar = (props: { progress: number }) => (
  <div className="progress">
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow={props.progress}
      aria-valuemin={0}
      aria-valuemax={100}
      style={{ width: props.progress + "%" }}
    >
      <span className="sr-only">{props.progress}% Complete</span>
    </div>
  </div>
);

export default SampleUploadBar;
